const state = {
	isLoading: []
};

const getters = {
	isLoading: state => {
		return state.isLoading;
	}
};

const mutations = {
	setIsLoading: (state, status) => {
		state.isLoading = status;
	}
};

const actions = {};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};
