import store from "../store";

const UserService = {
	async fetchCurrentUserData() {
		await store.dispatch("user/fetchCurrentUserData");
	},

	getCurrentUserData() {
		return store.getters["user/getCurrentUserData"];
	}
};

export default UserService;
