import Vue from "vue";
import store from "../store";
import TokenService from "../services/token.service";
import axios from "axios";

const ApiService = {
	async init(baseURL) {
		// TODO: Check Mutating global instance
		Vue.prototype.$axios = axios;

		let demoEnabled = baseURL !== "http://localhost:4040";

		const env = JSON.parse(localStorage.getItem("env"));
		let URL = env ? env.URL : baseURL;
		let MODE = env ? env.MODE : process.env.VUE_APP_MODE;
		if (!demoEnabled) {
			URL = "http://localhost:4040";
			MODE = "staging";
		}

		Vue.prototype.$axios.defaults.baseURL = URL;
		localStorage.setItem("env", JSON.stringify({ URL, MODE }));

		let token = TokenService.getToken();
		if (token) {
			this.setHeader();
		} else console.log("Could not set header.");

		this.mount401Interceptor();
		window.apiService = this;
	},

	async reInit(baseURL) {
		// TODO: Check Mutating global instance
		Vue.prototype.$axios = axios;
		Vue.prototype.$axios.defaults.baseURL = baseURL;
		let token = TokenService.getToken();
		if (token) {
			this.setHeader();
		} else console.log("Could not set header.");
		window.apiService = this;
	},

	setHeader() {
		axios.defaults.headers.common["Authorization"] = TokenService.getToken();
	},

	removeHeader() {
		Vue.prototype.$axios.defaults.headers.common = {};
	},

	get(resource) {
		return Vue.prototype.$axios.get(resource);
	},

	post(resource, data) {
		console.log("data", data);
		return Vue.prototype.$axios.post(resource, data);
	},

	put(resource, data) {
		return Vue.prototype.$axios.put(resource, data);
	},

	delete(resource) {
		return Vue.prototype.$axios.delete(resource);
	},

	/**
	 * Perform a custom Axios request.
	 *
	 * data is an object containing the following properties:
	 *  - method
	 *  - url
	 *  - data ... request payload
	 **/
	customRequest(data) {
		return Vue.prototype.$axios(data);
	},

	mount401Interceptor() {
		Vue.prototype.$axios.interceptors.response.use(
			response => {
				return response;
			},
			async error => {
				if (
					error.request.status === 401 &&
					error.config.url !== "/login" &&
					error.config.url !== "/logout" &&
					error.config.url !== "/signup" &&
					!store.getters["auth/isRefreshingToken"]
				) {
					// Check if token should be refreshed
					if (
						error.config.url !== "/refresh-token" &&
						TokenService.hasTokenExpired()
					) {
						const refreshRes = await store.dispatch("auth/refreshToken");
						if (refreshRes.status === 200) {
							TokenService.saveToken(refreshRes.data.accessToken.token);
							this.setHeader(); // Set Auth header to new token
							store.commit("auth/refreshingToken", false); // Set state

							// Resend the request with new token
							error.config.headers["Authorization"] = TokenService.getToken();
							return await Vue.prototype.$axios.request(error.config);
						} else {
							// If token couldn't be refreshed, log out the user
							return await store.dispatch("auth/logOut");
						}
					} else {
						//  The user is not authorized for the current action & should be logged out
						return await store.dispatch("auth/logOut");
					}
				}

				// Throw
				throw error;
			}
		);
	}
};

export default ApiService;
