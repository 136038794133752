// Module for flash messages

const state = {
	messages: [],
	messagesCount: 0
};

const getters = {
	getMessages: state => {
		return state.messages;
	}
};

const mutations = {
	push(state, message) {
		return state.messages.push(message);
	},

	shift(state) {
		return state.messages.shift();
	}
};

export default {
	namespaced: true,
	state,
	getters,
	mutations
};
