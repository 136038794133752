import Vue from "vue";
import TokenService from "../../services/token.service";
import ApiService from "../../services/api.service";
import router from "../../router";

const state = {
	isRefreshingToken: false,
};

const getters = {
	isRefreshingToken: (state) => {
		return state.isRefreshingToken;
	},
};

const mutations = {
	refreshingToken: (state, status) => {
		state.isRefreshingToken = status;
	},
};

const actions = {
	async signUp(ctx, data) {
		try {
			const response = await Vue.prototype.$axios.post("/signup", data);

			if (response.status === 200) {
				TokenService.saveToken(response.headers.authorization);
				TokenService.saveRefreshToken(response.data.refresh_token);
				await ApiService.reInit(
					process.env.VUE_APP_BASE_URL
						? process.env.VUE_APP_BASE_URL
						: "http://localhost:4040"
				);

				return {
					status: response.status,
					message: response.data.message,
				};
			}
		} catch (err) {
			if (err.message === "Network Error") {
				throw new Error("NETWORK_ERROR");
			} else {
				throw new Error(err.response.data.type);
			}
		}
	},

	async logIn(ctx, data) {
		try {
			const response = await Vue.prototype.$axios.post("/login", data);
			if (response.status === 200) {
				TokenService.saveToken(response.headers.authorization);
				TokenService.saveRefreshToken(response.data.refresh_token);
				await ApiService.reInit(
					process.env.VUE_APP_BASE_URL
						? process.env.VUE_APP_BASE_URL
						: "http://localhost:4040"
				);

				return {
					status: response.status,
					message: response.data.message,
				};
			}
		} catch (err) {
			if (err.message === "Network Error") {
				throw new Error("NETWORK_ERROR");
			} else {
				throw new Error(err.response.data.type);
			}
		}
	},

	async logOut() {
		try {
			await Vue.prototype.$axios.delete("/logout", {
				data: {
					refresh_token: TokenService.getRefreshToken(),
				},
			});
		} catch (err) {
			console.log(err);
		} finally {
			// TODO: Reset state
			TokenService.removeToken();
			TokenService.removeRefreshToken();

			location.href = "/login";
			// await router.push("/login");
		}
	},

	async refreshToken({ commit }) {
		try {
			commit("refreshingToken", true);
			return await Vue.prototype.$axios.post("/refresh-token", {
				refresh_token: TokenService.getRefreshToken(),
			});
		} catch (err) {
			return err;
		}
	},

	async checkPassword(ctx, password) {
		try {
			return await Vue.prototype.$axios.post("/check-password", { password });
		} catch (err) {
			if (err.message === "Network Error") {
				throw new Error("NETWORK_ERROR");
			} else {
				throw new Error(err.response.data.type);
			}
		}
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
