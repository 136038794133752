import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import { Intersect, Ripple } from "vuetify/lib/directives";

Vue.use(Vuetify, {
	directives: {
		Intersect,
		Ripple
	}
});

export default new Vuetify({
	theme: {
		themes: {
			light: {
				primary: "#1879d2",
				primaryLight: "#1879d2",
				primaryDark: "#003f99",

				secondary: "#d0d2d4",
				accent: "#8c9eff",

				// App
				appHeader: "#166cba",
				navDrawer: "#fdfdfd",
				navDrawerMobile: "#166cba",

				// Components
				dialogHeader: "#166cba",
				card: "#fdfdfd",
				tab: "#fdfdfd",
				chip: "#1879d2",

				info: "#64B5F6",
				error: "#d52626",
				success: "#3db227",
				warning: "#de8b1d",

				negative: "#ba4343",
				positive: "#6fc75e",

				mainBackground: "#f6f6f8",
				background: "#fdfdfd",
				text: "#292929",
				textLight: "#7f7f7f",
				border: "#f3f2f1",
				divider: "#e6e6e6"
			},
			dark: {
				primary: "#064eba",
				primaryLight: "#1e6ee7",
				primaryDark: "#023680",

				secondary: "#3b3b3b",
				accent: "#8c9eff",

				// App
				appHeader: "#181818",
				navDrawer: "#181818",
				navDrawerMobile: "#181818",

				// Components
				dialogHeader: "#353535",
				card: "#292929",
				tab: "#1e1e1e",
				chip: "#074aad",

				info: "#64B5F6",
				error: "#db2727",
				success: "#74c263",
				warning: "#de8b1d",

				negative: "#ba4343",
				positive: "#6fc75e",

				mainBackground: "#1c1c1c",
				background: "#292929",
				text: "#fff",
				textLight: "#e3e3e3",
				border: "#1f1f1f",
				divider: "#383838"
			}
		},
		options: { customProperties: true },
		light: localStorage.getItem("theme") === "light",
		dark: localStorage.getItem("theme") === "dark"
	}
});
