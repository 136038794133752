<template>
	<div class="preloader">
		<v-progress-circular
			indeterminate
			size="50"
			width="6"
			color="primary"
		></v-progress-circular>
		<div class="mt-4">
			Po ngarkohet, ju lutem prisni...
		</div>
	</div>
</template>

<script>
export default {
	name: "Preloader"
};
</script>

<style lang="scss">
.preloader {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: #ffffff;
	z-index: 996;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

// Animation
// Fade
.fade-enter-active {
	transition: opacity 0.15s;
}

.fade-leave-active {
	transition: opacity 0.15s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
 {
	opacity: 0;
}
</style>
