<template>
	<v-app>
		<!--    <transition name="fade">-->
		<!--      <Preloader v-if="isLoading"/>-->
		<!--    </transition>-->

		<router-view></router-view>

		<!--  Flash messages  -->
		<Flash />
	</v-app>
</template>

<script>
import {
	computed,
	onMounted,
	onUnmounted,
	reactive,
	watch
} from "@vue/composition-api";
import Flash from "./components/general/Flash";
import FlashService from "./services/flash.service";
import Preloader from "./components/general/Preloader";

export default {
	name: "App",
	components: { Flash, Preloader },
	setup(props, { root, emit }) {
		const state = reactive({
			isOnline: navigator.onLine
		});

		const isLoading = computed(() => {
			let status = root.$store.getters["general/isLoading"];
			console.log(status ? "is loading..." : "finished loading!");
			return status;
		});

		/** Lifecycles **/
		onMounted(async () => {
			window.addEventListener("online", updateOnlineStatus);
			window.addEventListener("offline", updateOnlineStatus);
		});

		onUnmounted(() => {
			window.removeEventListener("online", updateOnlineStatus);
			window.removeEventListener("offline", updateOnlineStatus);
		});

		/** Watchers **/
		watch(
			() => state.isOnline,
			v => {
				if (!v) {
					FlashService.pushNetworkError();
				} else {
					FlashService.pushNetworkSuccess();
				}
			}
		);

		/** Methods **/
		function updateOnlineStatus(e) {
			const { type } = e;
			state.isOnline = type === "online";
		}

		return {
			state,

			isLoading
		};
	}
};
</script>

<style lang="scss">
@import "assets/scss/style";
</style>
