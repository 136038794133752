import { AccessControl } from "accesscontrol";
import { grantsObject } from "../../../shared/permissions/roles";
import UserService from "../services/user.service";

const ac = new AccessControl();
ac.setGrants(grantsObject);

export default {
	methods: {
		isGrantedAccess: (action, resource) => {
			const role = UserService.getCurrentUserData()?.role;
			const isGranted = ac.can(role)[action](resource).granted;
			// const permission = ac.can(role)[action](resource);
			return isGranted;
		}
	}
};
