import store from "../store";

const FlashService = {
	push(message) {
		store.commit("flash/push", message);
	},

	pushConnRefused() {
		const message = {
			type: "error",
			msg: "Mungon lidhja me serverin."
		};
		store.commit("flash/push", message);
	},

	pushNetworkError() {
		const message = {
			type: "error",
			msg: "Mungon lidhja me rrjetin."
		};
		store.commit("flash/push", message);
	},

	pushNetworkSuccess() {
		const message = {
			type: "success",
			msg: "Lidhja me rrjetin u krye me sukses!"
		};
		store.commit("flash/push", message);
	}
};

export default FlashService;
