import Vue from "vue";
import VueRouter from "vue-router";
import TokenService from "../services/token.service";
import UserService from "../services/user.service";
import store from "../store";

const AppLayout = () => import("../components/layout/AppLayout");
const Login = () => import("../views/LoginScreen.vue");
const Classrooms = () => import("../views/Classrooms.vue");
const Classroom = () => import("../views/Classroom.vue");
const Forum = () => import("../views/Forum.vue");
const Assignments = () => import("../views/Assignments.vue");
const Calendar = () => import("../views/Calendar.vue");
const InteractiveBooks = () => import("../views/InteractiveBooks.vue");
const Materials = () => import("../views/Materials.vue");
const WhatsNew = () => import("../views/WhatsNew.vue");
const UnderConstruction = () => import("../views/UnderConstruction.vue");

Vue.use(VueRouter);
Vue.config.devtools = true;

function configRoutes() {
	return [
		{
			path: "/",
			component: AppLayout,
			redirect: "/classrooms",
			children: [
				{
					path: "login",
					name: "login",
					component: Login,
					meta: {
						public: true,
						onlyWhenLoggedOut: true,
					},
				},
				{
					path: "classrooms",
					name: "classrooms",
					component: Classrooms,
					children: [
						{
							path: ":classroomId",
							name: "classroom",
							components: {
								classroom: Classroom,
							},
							props: (route) => ({
								classroomId: route.params.classroomId?.toString(),
							}),
							children: [
								{
									path: "forum",
									name: "classroomForum",
									component: Forum,
									meta: {
										onlyWithToken: true,
									},
									props: (route) => ({
										classroomId: route.params.classroomId?.toString(),
									}),
								},
								{
									path: "materials",
									name: "classroomMaterials",
									component: Materials,
									meta: {
										onlyWithToken: true,
									},
									props: (route) => ({
										classroomId: route.params.classroomId?.toString(),
									}),
								},
								{
									path: "assignments",
									name: "classroomAssignments",
									component: Assignments,
									meta: {
										onlyWithToken: true,
									},
									props: (route) => ({
										classroomId: route.params.classroomId?.toString(),
									}),
								},
								{
									path: "interactive-books",
									name: "classroomInteractiveBooks",
									component: InteractiveBooks,
									meta: {
										onlyWithToken: true,
									},
									props: (route) => ({
										classroomId: route.params.classroomId?.toString(),
									}),
								},
								{
									path: "calendar",
									name: "classroomCalendar",
									component: Calendar,
									meta: {
										onlyWithToken: true,
									},
									props: (route) => ({
										classroomId: route.params.classroomId?.toString(),
									}),
								},
							],
							meta: {
								onlyWithToken: true,
							},
						},
					],
					meta: {
						onlyWithToken: true,
					},
				},
				{
					path: "forum",
					name: "forum",
					component: UnderConstruction,
					meta: {
						onlyWithToken: true,
					},
				},
				{
					path: "calendar",
					name: "calendar",
					component: UnderConstruction,
					meta: {
						onlyWithToken: true,
					},
				},
				{
					path: "interactive-books",
					name: "interactiveBooks",
					component: UnderConstruction,
					meta: {
						onlyWithToken: true,
					},
				},
				{
					path: "materials",
					name: "Materials",
					component: UnderConstruction,
					meta: {
						onlyWithToken: true,
					},
				},
				{
					path: "whats-new",
					name: "whatsNew",
					component: UnderConstruction,
					meta: {
						onlyWithToken: true,
					},
				},
			],
		},
		{
			path: "*",
			redirect: "classrooms",
		},
	];
}

const router = new VueRouter({
	mode: process.env.IS_ELECTRON ? "hash" : "history",
	routes: configRoutes(),
});

router.beforeEach(async (to, from, next) => {
	try {
		store.commit("general/setIsLoading", true);

		const isPublic = to.matched.some((record) => record.meta.public);
		const onlyWhenLoggedOut = to.matched.some(
			(record) => record.meta.onlyWhenLoggedOut
		);
		const onlyWithToken = to.matched.some(
			(record) => record.meta.onlyWithToken
		);
		const token = TokenService.getToken();
		const hasToken = !!token;

		if (hasToken) {
			if (!UserService.getCurrentUserData()) {
				await UserService.fetchCurrentUserData();
			}
			if (isPublic && onlyWhenLoggedOut) {
				next({ path: "/classrooms" });
			}
		} else {
			if (onlyWithToken) {
				next({ path: "/login" });
			}
		}
	} catch (err) {
		return err;
	} finally {
		next();
	}
});

router.afterEach(() => {
	Vue.nextTick(() => {
		store.commit("general/setIsLoading", false);
	});
});

export default router;
