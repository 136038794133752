import Vue from "vue";

const state = {
	posts: [],
};

const getters = {
	getPosts: (state) => {
		return state.posts;
	},
};

const mutations = {
	setPosts(state, posts) {
		state.posts = posts;
	},
	addPost(state, post) {
		state.posts.push(post);
	},
};

const actions = {
	async fetchPosts({ commit }, { classroomId, forumType }) {
		try {
			const response = await Vue.prototype.$axios.get("/posts", {
				params: { classRoomId: classroomId, forumType },
			});
			const posts = response.data;
			commit("setPosts", posts);
		} catch (err) {
			commit("setPosts", []);
			console.log(err);
		}
	},

	async createComment({ commit, dispatch }, { postId, text, attachment }) {
		try {
			await Vue.prototype.$axios.post("/comment", {
				postId,
				text,
				attachment,
			});
			// dispatch("fetchPosts", { classroomId, forumType });
		} catch (err) {
			console.log(err);
		}
	},

	async createPost(
		{ commit, dispatch },
		{ classroomId, forumType, text, attachment }
	) {
		try {
			await Vue.prototype.$axios.post("/post", {
				classRoom: classroomId,
				forumType,
				text,
				attachment,
			});
			// dispatch("fetchPosts", { classroomId, forumType });
		} catch (err) {
			console.log(err);
		}
	},

	async deletePost({ commit, dispatch }, { postId, classroomId }) {
		try {
			await Vue.prototype.$axios.delete("/post", {
				data: {
					postId,
					classRoomId: classroomId,
				},
			});
		} catch (err) {
			console.log(err);
		}
	},

	async deleteComment({ commit, dispatch }, { commentId, classroomId }) {
		try {
			await Vue.prototype.$axios.delete("/comment", {
				data: {
					commentId,
					classRoomId: classroomId,
				},
			});
		} catch (err) {
			console.log(err);
		}
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
