<template>
	<v-snackbar
		v-model="state.isVisible"
		:color="getColor"
		:timeout="3000"
		centered
		class="custom-snackbar"
		top
		outlined
		transition="scroll-y-transition"
	>
		<div v-if="state.currentMessage" class="d-flex align-center">
			<v-icon
				v-if="state.currentMessage.type === 'info'"
				class="snackbar-icon"
				:color="getColor"
			>
				mdi-information-outline
			</v-icon>
			<v-icon
				v-else-if="state.currentMessage.type === 'error'"
				class="snackbar-icon"
				:color="getColor"
			>
				mdi-alert-circle-outline
			</v-icon>
			<v-icon
				v-else-if="state.currentMessage.type === 'success'"
				class="snackbar-icon"
				:color="getColor"
			>
				mdi-check-circle-outline
			</v-icon>
			<v-icon
				v-else-if="state.currentMessage.type === 'warning'"
				class="snackbar-icon"
				:color="getColor"
			>
				mdi-alert-outline
			</v-icon>

			<span class="ml-2" v-html="state.currentMessage.msg"></span>
		</div>

		<template v-slot:action="{ attrs }">
			<v-btn class="ml-2" icon @click="state.isVisible = false">
				<v-icon :color="getColor">mdi-close</v-icon>
			</v-btn>
		</template>
	</v-snackbar>
</template>

<script>
import { computed, reactive, watch } from "@vue/composition-api";

export default {
	name: "Flash",
	setup(props, { root }) {
		const state = reactive({
			currentMessage: null,
			isVisible: false,
			messageLine: computed(() => root.$store.getters["flash/getMessages"])
		});

		function shiftMessage() {
			state.currentMessage = state.messageLine[0];
			state.isVisible = true;
			root.$store.commit("flash/shift");
		}

		watch(
			() => state.isVisible,
			async visible => {
				if (visible || !state.messageLine.length) return;
				await root.$nextTick();
				setTimeout(() => {
					shiftMessage();
				}, 200);
			}
		);

		watch(
			() => state.messageLine,
			() => {
				if (!state.isVisible) {
					shiftMessage();
				}
			}
		);

		// Get color based on message type
		const getColor = computed(() => {
			if (state.currentMessage?.type === "info") {
				return "info";
			} else if (state.currentMessage?.type === "error") {
				return "error";
			} else if (state.currentMessage?.type === "success") {
				return "success";
			} else if (state.currentMessage?.type === "warning") {
				return "warning";
			}
			return "info";
		});

		return {
			state,

			getColor
		};
	}
};
</script>

<style lang="scss" scoped>
.custom-snackbar::v-deep {
	z-index: 999999 !important;
	//top: 4px!important;

	.v-snack__wrapper {
		min-width: auto;
		background-color: var(--v-background-base) !important;
	}

	.v-snack__content {
		text-align: center;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 1rem;

		.snackbar-icon {
			margin-right: 10px;
		}
	}
}
</style>
