import Vue from "vue";
import FlashService from "../../services/flash.service";

const state = {
	classrooms: []
};

const getters = {
	getClassrooms: state => {
		return state.classrooms;
	},

	getClassroomById: state => classroomId => {
		return state.classrooms.find(classroom => classroom.id === classroomId);
	}
};

const mutations = {
	setClassrooms: (state, classrooms) => {
		state.classrooms = classrooms;
	},

	addClassroom: (state, classroom) => {
		state.classrooms.push(classroom);
	},

	removeClassroom: (state, classroomId) => {
		state.classrooms = state.classrooms.filter(
			classroom => classroom.id !== classroomId
		);
	}
};

const actions = {
	async fetchClassrooms({ commit }) {
		try {
			const response = await Vue.prototype.$axios.get("/classrooms");
			const classrooms = response.data.classrooms;

			commit("setClassrooms", classrooms);
		} catch (err) {
			console.log(err);
			return err;
		}
	},

	async createClassroom({ commit }, classroomObj) {
		try {
			const response = await Vue.prototype.$axios.post(
				"/classrooms/create",
				classroomObj
			);

			commit("addClassroom", response.data.createdClassroom);

			FlashService.push({
				type: "success",
				msg: "Klasa u krijua me sukses!"
			});
		} catch (err) {
			console.log(err);
			FlashService.push({
				type: "error",
				msg: "Ka ndodhur një gabim! Klasa nuk mund të krijohej."
			});
		}
	},

	async leaveClassroom({ commit }, classroomId) {
		try {
			await Vue.prototype.$axios.post(`/classrooms/leave/${classroomId}`);

			commit("removeClassroom", classroomId);
			FlashService.push({
				type: "success",
				msg: "Largimi nga klasa u krye me sukses!"
			});
		} catch (err) {
			console.log(err);
			FlashService.push({
				type: "error",
				msg: "Ka ndodhur një gabim! Largimi nga klasa dështoi."
			});
		}
	},

	async deleteClassroom({ commit }, classroomId) {
		try {
			await Vue.prototype.$axios.delete(`/classrooms/delete/${classroomId}`);

			commit("removeClassroom", classroomId);
			FlashService.push({
				type: "success",
				msg: "Klasa u fshi me sukses!"
			});
		} catch (err) {
			console.log(err);
			FlashService.push({
				type: "error",
				msg: "Ka ndodhur një gabim! Fshirja e klasës dështoi."
			});
		}
	}
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};
