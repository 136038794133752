import Vue from "vue";
import Vuex from "vuex";

// Modules
import auth from "./modules/auth.store";
import user from "./modules/user.store";
import forum from "./modules/forum.store";
import classrooms from "./modules/classrooms.store";
import flash from "./modules/flash.store";
import notifications from "./modules/notifications.store";
import general from "./modules/general.store";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";
Vue.config.devtools = true;

export default new Vuex.Store({
	modules: {
		auth,
		user,
		classrooms,
		forum,
		flash,
		notifications,
		general
	}
	// strict: debug,
	// plugins: debug ? [createLogger()] : []
});
