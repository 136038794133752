import jwt_decode from "jwt-decode";

const TOKEN_KEY = "access_token";
const REFRESH_TOKEN_KEY = "refresh_token";

/**
 * Manage the how Access Tokens are being stored and retrieved from storage.
 *
 * Current implementation stores to localStorage. Local Storage should always be
 * accessed through this instance.
 **/
const TokenService = {
	getToken() {
		return localStorage.getItem(TOKEN_KEY);
	},

	getRefreshToken() {
		return localStorage.getItem(REFRESH_TOKEN_KEY);
	},

	hasTokenExpired() {
		const tokenExp = TokenService.decodeToken(TokenService.getToken()).exp;
		const dateNow = Math.floor(Date.now() / 1000);
		// TODO: check again
		return dateNow >= tokenExp - 2; // -2 sec  for safety
	},

	saveToken(accessToken) {
		localStorage.setItem(TOKEN_KEY, accessToken);
	},

	saveRefreshToken(refreshToken) {
		localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
	},

	removeToken() {
		localStorage.removeItem(TOKEN_KEY);
	},

	removeRefreshToken() {
		localStorage.removeItem(REFRESH_TOKEN_KEY);
	},

	decodeToken(token) {
		return jwt_decode(token);
	}
};
window.tokenService = TokenService;
export default TokenService;
