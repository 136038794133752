import Vue from "vue";
import FlashService from "../../services/flash.service";

const state = {
	currentUserData: null
};

const getters = {
	getCurrentUserData: state => {
		return state.currentUserData;
	}
};

const mutations = {
	setCurrentUserData(state, data) {
		state.currentUserData = data;
	}
};

const actions = {
	async fetchCurrentUserData({ commit }) {
		try {
			const response = await Vue.prototype.$axios.get("/user-data");
			if (response.status === 200) {
				commit("setCurrentUserData", response.data.userData);
			}
		} catch (err) {
			if (err.message === "Network Error") {
				throw new Error("NETWORK_ERROR");
			} else {
				throw new Error(err.response.data.type);
			}
		}
	},

	async searchUsers(ctx, queryObj) {
		// queryObj: contains 'query' and 'role
		try {
			const response = await Vue.prototype.$axios.post(
				"/search/users",
				queryObj
			);
			return response.data.foundUsers;
		} catch (err) {
			// Might show "no internet" snackbar
			return [];
		}
	},

	async updateProfile({ dispatch }, userData) {
		try {
			const response = await Vue.prototype.$axios.put("/user-data", {
				userData
			});
			if (response.status === 200) {
				dispatch("fetchCurrentUserData");
				FlashService.push({
					type: "success",
					msg: "Profili u përditësua me sukses!"
				});
			}
		} catch (err) {
			FlashService.push({
				type: "error",
				msg: "Ka ndodhur një gabim! Profili nuk mund të përditësohej."
			});
			throw err;
		}
	},

	async changePassword({ dispatch }, passwords) {
		try {
			return await Vue.prototype.$axios.post("/change-password", { passwords });
		} catch (err) {
			if (err.message === "Network Error") {
				throw new Error("NETWORK_ERROR");
			} else {
				throw new Error(err.response.data.type);
			}
		}
	}
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};
