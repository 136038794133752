import Vue from "vue";

const state = {
	notifications: [],
	notificationCount: 0
};

const getters = {
	getNotifications: state => {
		return state.notifications;
	}
};

const mutations = {
	setNotifications(state, notifications) {
		state.notifications = notifications;
	}
};

const actions = {
	async fetchNotifications({ commit }) {
		try {
			const response = await Vue.prototype.$axios.get("/notifications");
			const notifications = response.data.notifications;
			commit("setNotifications", notifications);
		} catch (err) {
			if (err.message === "Network Error") {
				throw new Error("NETWORK_ERROR");
			} else {
				return err;
			}
		}
	}
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};
