const grantsObject = {
  admin: {},
  teacher: {
    user: {
      "read:any": ["*"],
      "update:own": ["*"],
      "delete:own": ["*"],
    },
    classroom: {
      "create:any": ["*"],
      "read:own": ["*"],
      "update:own": ["*"],
      "delete:own": ["*"],
    },
    post: {
      "read:any": ["*"],
      "create:any": ["*"],
      "update:own": ["*"],
      "delete:any": ["*"],
    },
    comment: {
      "read:any": ["*"],
      "create:any": ["*"],
      "update:own": ["*"],
      "delete:any": ["*"],
    },
    interactive: {
      "update:any": ["*"],
      "read:any": ["*"],
    },
  },
  student: {
    user: {
      "read:any": ["*"],
      "update:own": ["*"],
      "delete:own": ["*"],
    },
    classroom: {
      "read:own": ["*"],
    },
    post: {
      "read:any": ["*"],
    },
    comment: {
      "read:any": ["*"],
      "create:any": ["*"],
    },
    interactive: {
      "read:own": ["*"],
      "create:own": ["*"],
      "update:own": ["*"],
    },
  },
};

module.exports = { grantsObject };
